@font-face {
  font-family: 'Pribambas';
  src: url('/assets/fonts/Pribambas-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// palette for Slotvibe
// we only have landings so any other stuff is deleted
$bg-color: #642153;
$text-color: #fff;
$primary: #f8b147;
$danger: red; //needed for bootstrap for some reason
